import { animate, AnimationTriggerMetadata, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';

export const rotateUsingBoolean = (triggerName: string): AnimationTriggerMetadata => {
  return trigger(triggerName, [
    transition('true => false', [
      style({ transform: 'rotate(-180deg)' }),
      animate(400)
    ]),
    transition('false => true', [
      style({ transform: 'rotate(180deg)' }),
      animate(400)
    ])
  ]);
};

export const showHideUsingNgif = (triggerName: string): AnimationTriggerMetadata => {
  return trigger(triggerName, [
    transition(':enter', [
      style({ transform: 'translateY(-100%)' }),
      animate(400)
    ]),
    transition(':leave', [
      animate(400, style({ transform: 'translateY(-100%)' }))
    ])
  ]);
};

export const slideInTop = (): AnimationTriggerMetadata => {
  return trigger('slideInTop', [
    transition(':enter', [
      style({ transform: 'translateY(-50%)' }),
      animate('500ms ease-in', style({ transform: 'translateY(0)' })),
    ]),
    transition(":leave", [
      animate('500ms ease-out', style({ transform: 'translateY(-50%)' })),
    ])
  ]);
};

export const slideInBottom = (): AnimationTriggerMetadata => {
  return trigger('slideInBottom', [
    transition(':enter', [
      style({ transform: 'translateY(100%)' }),
      animate('500ms ease-in', style({ transform: 'translateY(0)' })),
    ]),
    transition(":leave", [
      animate('500ms ease-out', style({ transform: 'translateY(100%)' })),
    ])
  ]);
};

export const collapse = () => {
  return trigger('collapse', [
    state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
    state('true', style({ height: '0', visibility: 'hidden' })),
    transition('false => true', animate('300ms ease-in')),
    transition('true => false', animate('300ms ease-out'))
  ]);
};
